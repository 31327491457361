<template>
  <div class="layout-wrapper" :class="{'setting-color' : app.brandInfo && app.brandInfo.color}">
    <div class="header-wrapper">
      <div class="header">
        <!-- <template v-if="app.brandInfo && app.brandInfo.logoImage">
          <img :src="app.brandInfo.logoImage" height="52" style="width: auto;" />
        </template>
        <template v-else-if="app.brandInfo && !app.brandInfo.init">
          <img src="../../assets/images/logo.png" height="52" style="width: auto;" />
        </template> -->
        <template v-if="app.brandInfo && !app.brandInfo.init">
          <img class="logo" v-if="app.brandInfo.logoImage" :src="app.brandInfo.logoImage" />
          <span class="brand" v-else-if="app.brandInfo.brand">{{app.brandInfo.brand}}</span>
          <span class="brand" v-else>{{getWebsiteText(app.brandInfo.website)}}</span>
        </template>
      </div>
    </div>
    <div class="content-wrapper"><router-view /></div>
    <div class="footer-box">
      <div class="powerby-box">
        <!-- Powered by <p class="ic-powerby"></p> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { subCharByLength } from '@/utils/tools/utils';

export default {
  computed: {
    ...mapGetters(['app']),
  },
  watch: {
    lang: function() {
      this.setDocumentTitle();
    },
  },
  mounted() {
    this.setDocumentTitle();
  },
  methods: {
    setDocumentTitle() {
      document.title = this.$t('message.title');
    },
    getBrandText(brand) {
      return subCharByLength(brand, 30);
    },
    getWebsiteText(website) {
      if (!website) {
        return '';
      }
      let w = website.replace('https://', '').replace('http://', '');
      if (w.startsWith('www.')) {
        w = w.replace('www.', '');
      }
      return subCharByLength(w, 30);
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/variables.scss';

.layout-wrapper {
  padding-left: calc(100vw - 100%);
}

.header-wrapper {
  border-bottom: 1px solid $color-border;
  position: fixed;
  top: 0;
  left: calc(100vw - 100%);
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  z-index: 9;
  .header {
    max-width: 1150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 80px;
  }
  .logo {
    max-width: 50vw;
    height: auto;
    max-height: 52px;
    width: auto;
  }
}
.content-wrapper {
  max-width: 1150px;
  margin: 0 auto;
  padding-top: 101px;
}
.footer-box {
  margin-top: 180px;
  height: 40px;
  border-top: 1px solid #E5E5E5;
}
@media screen and (max-width: 1140px) {
.header-wrapper {
  padding: 0 10px;
  .header {
    height: 60px;
    display: flex;
    align-items: center;
    .header-left {
      margin-top: 0px;
      display: flex;
    }
    .logo {
      max-width: 50vw;
      height: auto;
      max-height: 26px;
      width: auto;
    }
    .brand {
      max-width: 50vw;
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .lang {
      margin: 10px;
    }
  }
}
.content-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.footer-box {
  justify-content: center;
}
}
</style>
